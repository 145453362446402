@import '../../shared/colors.module.scss';

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 56px;
}

.back {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 16px;

  span {
    color: $royal-blue;
    margin-left: 8px;
  }
}

.backButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.title {
  margin-top: 0;
}

.studentsTableHeader {
  height: 49px;
  border-bottom: 2px solid $geyser;
}

.lightText {
  color: $shuttle-gray;
}

.table {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background-color: white;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.table .tr {
  height: 44px !important;
}

.table .tr:first-of-type {
  margin-top: 20px;
}

.table td {
  height: 0;
  word-wrap: break-word;
}

.table th:first-of-type {
  padding-left: 24px;
}

.table th:last-of-type {
  padding-right: 24px;
}

.table td:first-of-type {
  padding-left: 24px;
}

.table td:last-of-type {
  padding-right: 24px;
}

.table th,
.table td {
  text-align: left;
  padding: 0 8px;
  line-height: 24px;
}

.table th {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $shuttle-gray;
}

.tablePadding {
  height: 16px !important;
}

.showMore {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 16px;
  margin-top: -1px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.showMoreButton {
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $catskill-whiteapprox;
  border-radius: 4px;
  cursor: pointer;

  span {
    font-weight: 500;
  }
}

@media (max-width: 481px) {
  .container {
    padding: 24px 16px;
  }
}

@media screen and (max-width: 768px) {
  .tablePadding {
    display: none;
  }

  .table {
    background: none;
    box-shadow: none;
    border: none;
  }

  .table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .table .tr {
    height: unset !important;
    display: block;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px $botticelli;
    background-color: white;
    margin-bottom: 16px;
    padding: 0;
  }

  .table td {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $botticelli;
    height: auto;
    padding: 16px;
  }

  .table th:first-of-type {
    padding-left: 16px;
  }

  .table th:last-of-type {
    padding-right: 16px;
  }

  .table td:first-of-type {
    padding-left: 16px;
  }

  .table td:last-of-type {
    padding-right: 16px;
  }

  .table td::before {
    /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
    content: attr(data-label);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $shuttle-gray;
  }

  .table td:last-child {
    border-bottom: 0;
  }

  .table .tr:first-of-type {
    margin-top: 0;
  }
}
