$athens-grayapprox: #f7f8fa;
$ebony-clay: #262c39;
$royal-blue: #2e59e2;
$geyser: #cbd5e0;
$catskill-whiteapprox: #e2e8f0;
$athens-gray: #e7e8eb;
$shuttle-gray: #61697e;
$observatory: #03876e;
$zumthor: #dee6ff;
$klein-blue: #072da8;
$carnation: #f56565;
$outer-space: #252828;
$botticelli: #cfdce5;
$alto: #d0d0d0;
$tea-green: #cbf4c9;
$persian-red: #ce3636;
$cosmos: #ffdcdc;
$frosted-mint: #dcfffa;
$niagara: #069d8a;
