@import '../../shared/colors.module.scss';

.container {
  max-width: 970px;
  margin: 0 auto;
  padding: 32px 56px;
  padding-bottom: 56px;
}

.content {
  display: flex;
  align-items: flex-start;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.helpContainer {
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background-color: white;
  padding: 8px;
}

.helpLabel {
  margin-left: 8px;
  margin-right: 24px;
}

.tipsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $catskill-whiteapprox;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  width: 104px;
  cursor: pointer;
}

.responsiveNavigationList {
  display: none;
}

.navigationList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 150px;
  background: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  border-radius: 4px;
  padding: 14px 16px;
  padding-right: 35px;
  margin: 0;
  margin-right: 24px;
  box-sizing: border-box;

  li {
    display: block;
    margin: 11px 0;
    cursor: pointer;
  }
}

.navigationItemSelected {
  font-weight: 700;
  color: $royal-blue;
}

.sections {
  width: 100%;
}

.formContainer {
  padding-top: 24px;
}

.formContainer:first-of-type {
  padding-top: 0;
}

.saveButtonContainer {
  margin-top: 24px;
  margin-bottom: 8px;
}

.editCourseBio {
  padding: 18px 16px;
  padding-bottom: 12px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background-color: white;
  box-sizing: border-box;
}

.editCourseBioLabel {
  margin: 0;
  font-weight: bold;
}

.editCourseBioDescription {
  font-size: 14px;
  margin: 0;
  margin-top: 4px;

  a {
    font-weight: 500;
    color: $royal-blue;
  }
}

@media (max-width: 768px) {
  .helpLabel {
    display: none;
  }

  .responsiveNavigationList {
    display: block;
    width: 100%;
    margin-bottom: 16px;
  }

  .navigationList {
    display: none;
  }

  .content {
    flex-direction: column;
  }
}

@media (max-width: 481px) {
  .container {
    padding: 16px;
  }

  .formContainer {
    padding-top: 16px;
  }

  .titleContainer {
    margin-bottom: 16px;
  }

  .title {
    margin: 0;
  }
}
