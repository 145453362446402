@import './shared/colors.module.scss';
@import './shared/style.module.scss';

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $athens-grayapprox;
  color: $ebony-clay;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: unset;
}

.errorLabel {
  font-size: 14px;
  color: $carnation;
  margin-top: 16px;
  margin-bottom: 0;
}
