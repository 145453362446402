@import '../../shared/colors.module.scss';

.container {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background: white;
  padding: 19px 36px;
  padding-left: 30px;
}

.content {
  display: flex;
  align-items: center;
}

.contentLeft {
  flex: 1;
  margin-right: 32px;
}

.iconContainer {
  height: 32px;
  width: 32px;
  margin-right: 8px;
}

.text {
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.button {
  color: $royal-blue;
  font-weight: 500;
  margin-right: 22px;
  cursor: pointer;
}

.closeIcon {
  height: 18px;
  width: 16px;
  cursor: pointer;

  path {
    fill: $alto;
  }
}

.text::before {
  content: '';
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
  }

  .contentLeft {
    align-items: flex-start;
    margin-right: 16px;
    margin-bottom: 8px;
  }

  .iconContainer {
    position: absolute;
  }

  .text {
    line-height: 30px;
  }

  .text::before {
    content: '\a0\a0\a0\a0\a0\a0\a0\a0\a0';
  }

  .closeIcon {
    position: absolute;
    top: 19px;
    right: 24px;
  }
}

@media (max-width: 481px) {
  .container {
    padding: 16px;
  }

  .contentLeft {
    margin-right: 23px;
  }

  .closeIcon {
    top: 16px;
    right: 16px;
  }
}
