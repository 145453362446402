@import '../../shared/colors.module.scss';

.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  position: relative;
}

.labelsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  color: $ebony-clay;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
}

.secondaryLabel {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
}

.input {
  background: white;
  border: 1px solid $geyser;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  height: 48px;
  padding: 14px 12px;
  outline: none;
  -webkit-appearance: none;
  resize: none;
}

input {
  -webkit-appearance: none;
}

::placeholder {
  font-size: 16px;
  color: rgba(38, 44, 57, 0.3);
}

.long {
  height: 105px;
  line-height: 24px;
}

.veryLong {
  height: 220px;
  line-height: 24px;
}

.error {
  border: 1px solid $carnation;
}
