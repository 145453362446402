select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  background: white;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 14px 10px;
  padding-right: 30px;
  width: 100%;
}
