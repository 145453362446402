@import '../../shared/colors.module.scss';

.popupContainer {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  overflow: scroll;
  box-sizing: border-box;
}

.container {
  position: relative;
  width: 100%;
  max-width: 504px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background-color: $athens-grayapprox;
  padding: 16px;
  padding-top: 32px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-height: 100%;
  overflow: scroll;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.message {
  width: 100%;
  text-align: center;
  white-space: pre-wrap;
  margin: 0;
  margin-bottom: 16px;
}

.buttonsContainer {
  width: 100%;
  display: flex;
}

.button {
  width: 100%;
}

.button:first-of-type {
  margin-right: 8px;
}

.button:last-of-type {
  margin-left: 8px;
}

@media (max-width: 481px) {
  .popupContainer {
    padding: 16px;
  }

  .container {
    padding: 24px;
  }

  .closeButton {
    height: 14px;
    width: 14px;
  }

  .buttonsContainer {
    flex-direction: column;
  }

  .button:first-of-type {
    margin-right: 0;
    margin-bottom: 8px;
  }

  .button:last-of-type {
    margin-left: 0;
  }
}

@media (max-width: 360px) {
  .closeButton {
    height: 12px;
    width: 12px;
  }
}
