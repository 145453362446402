@import '../../../shared/colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  border: 1px solid $geyser;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 18px 16px;
}

.label {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.description {
  font-size: 14px;
  line-height: 21px;
  margin-top: 4px;
  margin-bottom: 8px;
}

.inputContainer {
  width: 100%;
  margin-top: 8px;
}

.inputContentContainer {
  position: relative;
}

.removeButton {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.removeButton path {
  stroke: $shuttle-gray;
}

.addFaq {
  display: flex;
  cursor: pointer;
  margin-top: 8px;
  margin-left: 14px;
  margin-bottom: 5px;
}

.addFaqLabel {
  font-size: 14px;
  font-weight: 600;
  margin-left: 9px;
}
