@import '../../shared/colors.module.scss';

.container {
  max-width: 1326px;
  margin: 0 auto;
  padding: 40px 56px;
}

.courseInformationContainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 72px;
}

.courseImage {
  width: 112px;
  height: 112px;
  background: white;
  border-radius: 8px;
  background-size: cover;
}

.coverArtPlaceholderContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.courseInformation {
  flex: 1;
  margin: 0 24px;
}

.courseTitle {
  margin-bottom: 16px;
  margin-top: 7px;
  word-break: break-word;
}

.courseURL {
  color: $royal-blue;
  line-height: 24px;
  word-break: break-all;
}

.headerButtonsContainer {
  display: flex;
}

.shareCourseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background: white;
  margin-right: 16px;
  cursor: pointer;
  box-sizing: border-box;

  span {
    font-weight: 600;
    margin-left: 8px;
  }
}

.courseUrlsContainer {
  display: flex;
  align-items: center;
}

.viewMyCourseButton {
  margin: 0 16px;
}

.courseStatusContainer {
  display: flex;
  align-items: center;
  padding: 7px 16px;
  padding-right: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background: white;
}

.courseStatus {
  font-weight: 600;
  margin-left: 3px;
  text-transform: capitalize;
}

.changeCourseStatusButton {
  border-radius: 4px;
  background-color: $catskill-whiteapprox;
  padding: 8px 13px;
  font-weight: 600;
  font-size: 14px;
  margin-left: 24px;
  cursor: pointer;
}

.navigation {
  display: flex;
  border-bottom: 1px solid $botticelli;
  margin: 0;
  margin-bottom: 24px;
  padding: 0;
  overflow: scroll;
  overflow-y: hidden;
}

.navigation::-webkit-scrollbar {
  width: 0px;
  height: 0;
  background: transparent;
}

.navigationItem {
  position: relative;
  display: block;
  color: $shuttle-gray;
  font-weight: 500;
  padding: 8px 10px;
  padding-top: 0;
  margin-right: 29px;
  cursor: pointer;
}

.navigationItemSelected {
  color: $ebony-clay;
  font-weight: 600;
}

.navigationItemSelectedIndicator {
  height: 2px;
  width: 100%;
  background: $ebony-clay;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.episodeSectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.addEpisodeContainer {
  width: 180px;
}

.episodeElement {
  margin-bottom: 24px;
}

.analyticsFilterOptionsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  margin-bottom: 56px;
}

.analyticsPeriodLabel {
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  margin: 0;
  margin-right: 16px;
}

.analyticsHorizontalContainer {
  display: flex;
  margin-top: 60px;
}

.analyticsType {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
}

.analyticsTableContainer {
  margin-top: 16px;
}

.analyticsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 8px;
  margin-left: -8px;
  width: calc(100% + 14px);
}

.analyticsElement {
  width: 254px;
  height: 254px;
  padding: 8px;
  box-sizing: border-box;
}

.analyticsBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  word-break: break-all;
  text-align: center;
}

.analyticsValue {
  margin-top: 0;
  margin-bottom: 12px;
  margin-left: 8px;
  margin-right: 8px;
}

.analyticsDescription {
  color: $shuttle-gray;
  margin: 0;
}

.analyticsTypeContainer {
  margin-bottom: 60px;
}

// .analyticsTypeContainer:first-of-type {
//   margin-right: 12px;
// }

// .analyticsTypeContainer:last-of-type {
//   margin-left: 12px;
// }

.editCourseContainer {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  padding: 32px;
}

.editCourseInfoContainer {
  flex: 1;
}

.editCourseTitle {
  margin-top: 4px;
  margin-bottom: 15px;
}

.editCourseDescription {
  margin: 0;
  line-height: 24px;
}

.editButtonContainer {
  width: 139px;
  margin-left: 16px;
}

table {
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background-color: white;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.studentsTableHeader {
  height: 49px;
  border-bottom: 2px solid $geyser;
}

table td {
  height: 83px;
  word-wrap: break-word;
}

table th:first-of-type {
  padding-left: 24px;
}

table th:last-of-type {
  padding-right: 24px;
}

table td:first-of-type {
  padding-left: 24px;
}

table td:last-of-type {
  padding-right: 24px;
}

table th,
table td {
  text-align: left;
  padding: 0 8px;
  line-height: 24px;
}

table th {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $shuttle-gray;
}

@media (max-width: 1200px) {
  .courseInformationContainer {
    flex-direction: column;
  }

  .courseInformation {
    margin: 16px 0;
  }
}

@media (max-width: 992px) {
  .analyticsHorizontalContainer {
    flex-direction: column;
  }

  .analyticsTypeContainer:first-of-type,
  .analyticsTypeContainer:last-of-type {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  table {
    background: none;
    box-shadow: none;
    border: none;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    display: block;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px $botticelli;
    background-color: white;
    margin-bottom: 16px;
    padding: 0;
  }

  table td {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $botticelli;
    height: auto;
    padding: 16px;
  }

  table th:first-of-type {
    padding-left: 16px;
  }

  table th:last-of-type {
    padding-right: 16px;
  }

  table td:first-of-type {
    padding-left: 16px;
  }

  table td:last-of-type {
    padding-right: 16px;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $shuttle-gray;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

@media (max-width: 1127px) {
  .analyticsContainer {
    justify-content: flex-start;
  }
  .analyticsElement {
    width: 33.3%;
  }
}

@media (max-width: 877px) {
  .analyticsContainer {
    justify-content: flex-start;
  }
  .analyticsElement {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .courseInformationContainer {
    flex-direction: column;
    margin-bottom: 32px;
  }

  .courseTitle {
    margin-top: 16px;
    margin-bottom: 12px;
  }

  .headerButtonsContainer {
    width: 100%;
    flex-direction: column;
  }

  .courseUrlsContainer {
    margin-top: 8px;
  }

  .viewMyCourseButton {
    flex: 1;
    margin: 0;
    margin-right: 8px;
  }

  .shareCourseButton {
    margin: 0;
  }

  .courseStatusContainer {
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
  }

  .actionButtonsContainer {
    width: 100%;
    margin-top: 32px;
  }

  .episodeSectionHeader {
    flex-direction: column;
    align-items: stretch;
  }

  .addEpisodeContainer {
    width: 100%;
    margin-top: 16px;
  }

  .episodeElement {
    margin-bottom: 16px;
  }

  .analyticsElement {
    width: 100%;
    height: 160px;
  }

  .editCourseContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .editCourseInfoContainer {
    flex-direction: column;
  }

  .editButtonContainer {
    margin: 0;
    margin-top: 16px;
    width: 100%;
  }
}

@media (max-width: 575px) {
  .headerButtonsContainer {
    flex-direction: column;
  }

  .shareCourseButton {
    height: 48px;
    margin: 0;
  }
}

@media (max-width: 481px) {
  .container {
    padding: 24px 16px;
  }

  .courseTitle {
    margin-bottom: 16px;
  }

  .editCourseContainer {
    padding: 16px;
  }

  .analyticsFilterOptionsContainer {
    margin-bottom: 16px;
  }

  .analyticsHorizontalContainer {
    margin-top: 24px;
  }

  .analyticsTypeContainer {
    margin-bottom: 16px;
  }
}

@media (max-width: 391px) {
  .courseStatusContainer {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
  }

  .changeCourseStatusButton {
    margin: 0;
    margin-top: 16px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
  }
}
