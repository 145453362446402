@import '../../shared/colors.module.scss';

.timePickerContainer {
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #cbd5e0;
  background-color: #ffffff;
  width: 240px;

  .applyContainer {
    border-top: 1px solid $catskill-whiteapprox;
    padding: 8px;
  }

  .apply {
    background: $catskill-whiteapprox;
    padding: 8px 0;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
  }
}

.timePickerComponents {
  display: flex;
  border-bottom: 1px solid $catskill-whiteapprox;
  height: 49px;

  .timePickerComponentLabel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: rgba(38, 44, 57, 0.3);
    border-right: 1px solid $catskill-whiteapprox;
  }

  .timePickerComponentLabel:last-of-type {
    border-right: none;
  }
}

.timePicker {
  display: flex;
  max-height: 184px;

  .timePickerElement {
    overflow: scroll;
    width: 100%;
    padding: 8px 0;
    border-right: 1px solid $catskill-whiteapprox;

    ul {
      padding: 0;
      margin: 0;
    }

    li {
      display: block;
      text-align: center;
      padding: 5px 0;
      cursor: pointer;
    }

    li:hover {
      background: $catskill-whiteapprox;
    }
  }

  .timePickerElement:last-of-type {
    border-right: none;
  }
}

.selected {
  background: $catskill-whiteapprox;
}
