@import '../../shared/colors.module.scss';

.popupContainer {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  overflow: scroll;
  box-sizing: border-box;
}

.container {
  position: relative;
  width: 100%;
  max-width: 504px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background-color: $athens-grayapprox;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-height: 100%;
  overflow: scroll;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 0;
}

.statusesContainer {
  width: 100%;
  margin-top: 16px;
}

.status {
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background-color: white;
  width: 100%;
  padding: 24px;
  margin-bottom: 16px;
  box-sizing: border-box;
  cursor: default;
}

.status:hover {
  border-color: $royal-blue;
}

.status {
  a {
    color: $royal-blue;
  }

  a:hover {
    text-decoration: underline;
  }
}

.statusSelected {
  border-color: $royal-blue;
}

.statusText {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.statusNameContainer {
  display: flex;
  align-items: center;
}

.statusName {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.currentStatus {
  font-size: 16px;
  line-height: 24px;
  color: $shuttle-gray;
  margin-left: 5px;
}

.statusDescription {
  font-size: 16px;
  line-height: 24px;
  color: $shuttle-gray;
  margin-top: 4px;
}

.saveButton {
  margin-bottom: 8px;
  width: 100%;
}

.cancelButton {
  width: 100%;
}

.statusUpdatedAlert {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.alertIllustration {
  height: 140px;
  width: 140px;
  margin-top: -16px;
  margin-bottom: -8px;
}

.statusUpdatedAlertTitle {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
}

.statusUpdatedAlertDescription {
  margin: 0 4px;
}

.courseURLBox {
  margin-top: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background-color: white;
  width: 100%;
  padding: 14px;
  box-sizing: border-box;

  a {
    color: $royal-blue;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (max-width: 481px) {
  .popupContainer {
    padding: 16px;
  }

  .container {
    padding: 24px;
  }

  .closeButton {
    height: 14px;
    width: 14px;
  }

  .status {
    padding: 16px;
  }

  .statusImage {
    display: none;
  }

  .statusNameContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .currentStatus {
    margin: 0;
  }

  .statusText {
    margin: 0;
  }

  .statusDescription {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 360px) {
  .closeButton {
    height: 12px;
    width: 12px;
  }
}
