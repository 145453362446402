@import './colors.module.scss';

h1 {
  font-size: 40px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

p {
  line-height: 24px;
}

.semiBold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.textSM {
  font-size: 14px;
  line-height: 21px;
}

.textBase {
  font-size: 16px;
  line-height: 24px;
}

.zeroMargin {
  margin: 0;
}

.zeroPadding {
  padding: 0;
}

.errorContainer {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.errorMessage {
  font-size: 14px;
  font-weight: 500;
  color: $persian-red;
  margin-left: 8px;
}

button {
  padding: 0;
  border: none;
}

@media (max-width: 481px) {
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }
}
