@import '../../shared/colors.module.scss';

.container {
  max-width: 821px;
  margin: 0 auto;
  padding: 32px 56px;
  padding-bottom: 56px;
}

.formContainer {
  padding-top: 24px;
}

.saveButtonContainer {
  margin-top: 24px;
}

.deleteEpisodeButtonContainer {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteEpisodeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.deleteEpisodeText {
  flex: 1;
  font-size: 16px;
  line-height: 24px;
  color: $persian-red;
  margin-left: 8px;
}

@media (max-width: 481px) {
  .container {
    padding: 24px 16px;
  }

  .formContainer {
    padding-top: 16px;
  }
}
