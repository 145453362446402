@import '../../../shared/colors.module.scss';

.container {
  background: white;
  border: 1px solid $geyser;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 18px 16px;
}

.label {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.description {
  font-size: 14px;
  line-height: 21px;
  margin-top: 4px;
  margin-bottom: 16px;

  span {
    color: $royal-blue;
    font-weight: 500;
    cursor: pointer;
  }
}

.checkboxes {
  display: flex;
}

.checkbox {
  margin-right: 32px;
}

.checkbox:last-of-type {
  margin-right: 0;
}

.popupContainer {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  overflow: scroll;
  box-sizing: border-box;
}

.popupContentContainer {
  position: relative;
  width: 100%;
  max-width: 620px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background-color: $athens-grayapprox;
  padding: 16px;
  padding-top: 48px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-height: 100%;
  overflow: scroll;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.popupTitle {
  text-align: center;
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

.popupDescription {
  text-align: center;
  margin-top: 4px;
  margin-bottom: 20px;
}

.emailPreview {
  width: 100%;
  max-width: 556px;
}

@media (max-width: 481px) {
  .popupContainer {
    padding: 16px;
  }

  .popupContentContainer {
    padding: 24px;
  }

  .closeButton {
    height: 14px;
    width: 14px;
  }

  .popupTitle {
    font-size: 20px;
  }
}

@media (max-width: 360px) {
  .closeButton {
    height: 12px;
    width: 12px;
  }
}
