@import '../../shared/colors.module.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background-color: white;
}

.label {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 16px;
}

.valueSeparator {
  color: $catskill-whiteapprox;
  margin: 0 10px;
}

.editButton {
  background-color: $catskill-whiteapprox;
  border-radius: 4px;
  color: $ebony-clay;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  padding: 9px 19px;
  margin-left: 16px;
  cursor: pointer;
}

@media (max-width: 481px) {
  .container {
    padding: 18px 16px;
    flex-direction: column;
    align-items: flex-start;
  }

  .label {
    margin-bottom: 11px;
  }

  .editButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 0;
  }
}
