@import '../../shared/colors.module.scss';

.button {
  border: inherit;
  color: white;
  text-align: center;
  background: #2e59e2;
  border-radius: 4px;
  width: 100%;
  padding: 16px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  box-sizing: border-box;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.buttonSecondary {
  @extend .button;
  background: white;
  color: $ebony-clay;
  border: 1px solid $geyser;
}

.buttonTertiary {
  @extend .button;
  background: $catskill-whiteapprox;
  color: $ebony-clay;
  box-shadow: none;
  font-size: 14px;
}

.green {
  background: $niagara;
}

.red {
  background: $persian-red;
  color: white;
}

.small {
  padding: 11px 16px;
}

.big {
  padding: 16px 16px;
}

.zeroHorizontalPadding {
  padding-left: 0;
  padding-right: 0;
}

.icon {
  margin-right: 8px;
}

.disabled {
  opacity: 0.3;
}

@media (max-width: 481px) {
  .big {
    padding: 16px;
  }
}
