@import '../../shared/colors.module.scss';

.container {
  background: white;
}

.containerBorder {
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
}

.headerContainer {
  display: flex;
  align-items: center;
  background: $frosted-mint;
  padding: 17px 16px;
}

.textContainer {
  display: flex;
  align-items: center;
  flex: 1;
}

.text {
  margin: 0 10px;
  font-weight: 500;
  line-height: 20px;
}

.infoIconContainer {
  height: 21px;
  width: 21px;
}

.infoIcon {
  path {
    fill: $ebony-clay;
  }
}

.actionsContainer {
  display: flex;
  align-items: center;
}

.actionContainer {
  display: flex;
  align-items: center;
  margin-right: 32px;
  height: 22px;
  user-select: none;
  cursor: pointer;

  span {
    font-weight: 500;
    margin-right: 8px;
  }
}

.actionContainer:last-of-type {
  margin-right: 0;
}

.readIconContainer {
  width: 22px;
  display: flex;
}

.tipTextContainer {
  padding: 16px 20px;
}

.tipText {
  line-height: 25px;
  margin: 0;
}

@media (max-width: 767px) {
  .headerContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .actionsContainer {
    margin-top: 12px;
  }

  .actionContainer {
    margin-right: 16px;
  }

  .infoIconContainer {
    display: none;
  }

  .text {
    margin-left: 0;
  }
}
