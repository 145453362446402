@import '../../../shared/colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  border: 1px solid $geyser;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 18px 16px;
}

.label {
  margin: 0;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}

.secondaryLabel {
  @extend .label;
  font-weight: 400;
}

.optionalLabel {
  opacity: 0.5;
}

.description {
  font-size: 14px;
  line-height: 21px;
  margin: 0;
}

.inputContainer {
  margin-top: 11px;
  margin-bottom: 16px;
  width: 100%;
}

.addCoupon {
  display: flex;
  cursor: pointer;
  margin-top: 16px;
  margin-left: 14px;
  margin-bottom: 5px;
}

.addCouponLabel {
  font-size: 14px;
  font-weight: 600;
  margin-left: 9px;
}

.couponElementContainer {
  width: 100%;
  margin-top: 16px;
}

.selectElements {
  display: flex;
  width: 100%;
}

.selectCurrency {
  width: 25%;
  margin-right: 8px;
}

.selectPrice {
  width: 37.5%;
  margin-left: 8px;
}

.tipContainer {
  width: 100%;
  margin-top: 16px;
}

@media (max-width: 900px) {
  .selectCurrency {
    width: 33.33%;
  }

  .selectPrice {
    width: 33.33%;
  }
}

@media (max-width: 715px) {
  .selectElements {
    flex-direction: column;
  }

  .selectCurrency {
    width: 100%;
    margin: 0;
    margin-bottom: 8px;
  }

  .selectPrice {
    width: 100%;
    margin: 8px 0;
  }

  .selectPrice:last-of-type {
    margin-bottom: 0;
  }
}
