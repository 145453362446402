.coursesContainer {
  max-width: 1326px;
  margin: 0 auto;
  padding: 0 56px;
  padding-top: 27px;
  padding-bottom: 160px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.createCourseButton {
  border: none;
  color: white;
  text-align: center;
  background: #2e59e2;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.09), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 180px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
}

.promptContainer {
  margin-bottom: 16px;
}

.emptyCourses {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 82px 0;
  padding-bottom: 65px;
  background: white;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.noCoursesLabel {
  font-weight: 600;
  margin-top: 26px;
  margin-bottom: -5px;
}

.courseContainer {
  margin-bottom: 24px;
}

.feedbackCTAContainer {
  position: fixed;
  bottom: 56px;
  right: 56px;
}

@media (max-width: 481px) {
  .coursesContainer {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 95px;
  }

  .titleContainer {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
  }

  .createCourseButton {
    width: 100%;
  }

  .emptyCourses {
    padding: 32px;
    text-align: center;
  }

  .courseContainer {
    margin-bottom: 16px;
  }

  .feedbackCTAContainer {
    left: 0;
    right: 0;
    bottom: 16px;
    margin: 0 16px;
  }
}
