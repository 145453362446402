@import '../../shared/colors.module.scss';

.container {
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $botticelli;
  background-color: white;
}

.value {
  font-weight: 500;
  margin-right: 5px;
}

.secondaryValue {
  font-weight: 500;
  color: $shuttle-gray;
}

.totalContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.totalTextContainer {
  display: flex;
}

.eventCol {
  width: 65%;
}

.totalCol {
  width: 35%;
}

.header {
  height: 49px;
  border-bottom: 2px solid $geyser;
}

.rowAction {
  cursor: pointer;
}

.rowAction:hover {
  background: $athens-grayapprox !important;
}

.table {
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background-color: white;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.table .tr {
  height: 44px !important;
}

.table .tr:first-of-type {
  margin-top: 20px;
}

.table td {
  height: 0;
  word-wrap: break-word;
}

.table th:first-of-type {
  padding-left: 24px;
}

.table th:last-of-type {
  padding-right: 24px;
}

.table td:first-of-type {
  padding-left: 24px;
}

.table td:last-of-type {
  padding-right: 24px;
}

.table th,
.table td {
  text-align: left;
  padding: 0 8px;
  line-height: 24px;
}

.table th {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $shuttle-gray;
}

.tablePadding {
  height: 16px !important;
}

@media (max-width: 1200px) {
  .eventCol {
    width: 60%;
  }

  .totalCol {
    width: 40%;
  }
}

@media screen and (max-width: 768px) {
  .eventCol,
  .totalCol {
    width: 100%;
    display: block;
  }

  .tablePadding {
    display: none;
  }

  .table {
    background: none;
    box-shadow: none;
    border: none;
  }

  .table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .table .tr {
    height: unset !important;
    display: block;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px $botticelli;
    background-color: white;
    margin-bottom: 16px;
    padding: 0;
  }

  .table td {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $botticelli;
    height: auto;
    padding: 16px;
  }

  .table th:first-of-type {
    padding-left: 16px;
  }

  .table th:last-of-type {
    padding-right: 16px;
  }

  .table td:first-of-type {
    padding-left: 16px;
  }

  .table td:last-of-type {
    padding-right: 16px;
  }

  .table td::before {
    /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
    content: attr(data-label);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $shuttle-gray;
  }

  .table td:last-child {
    border-bottom: 0;
  }

  .table .tr:first-of-type {
    margin-top: 0;
  }
}
