@import '../../shared/colors.module.scss';

.formContainer {
  margin: 0 auto;
  display: flex;
  max-width: 400px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 16px;
  padding-bottom: 56px;
}

.title {
  margin-top: 62px;
  margin-bottom: 40px;
  text-align: center;
}

.resetPasswordTitle {
  margin-bottom: 0;
}

.description {
  text-align: center;
  margin-bottom: 40px;
}

.signInButtonContainer {
  margin-top: 8px;
  margin-bottom: 16px;
  width: 100%;
}

.signUp {
  font-size: 14px;
  margin: 0;
  text-align: center;

  a,
  span {
    font-weight: 600;
    cursor: pointer;
  }
}

.error {
  color: $carnation;
  margin-top: 0;
  margin-bottom: 8px;
}

@media (max-width: 481px) {
  .formContainer {
    padding-top: 16px;
  }

  .title {
    margin: revert;
  }
}
