@import '../../shared/colors.module.scss';

.container {
  position: relative;
}

.select {
  position: relative;
  background: white;
  border: 1px solid $geyser;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 14px 10px;
  padding-right: 30px;
  width: 100%;
}

.selectOption {
  font-size: 16px;
  margin-right: 8px;
  cursor: default;
}

.selectArrow {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 8px;
}

.list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.listItem {
  display: block;
  padding: 11px 16px;
  cursor: pointer;
}

.listItem:hover {
  background: $athens-grayapprox;
}

.dropdownContainer {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: -8px;
  padding-bottom: 8px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px $geyser;
  background-color: white;
  box-sizing: border-box;
  z-index: 999;
}

.inputSearch {
  padding: 9px;
  border-radius: 4px;
  border: solid 1px $geyser;
  background-color: white;
  outline: none;
  -webkit-appearance: none;
  resize: none;
  margin-top: 16px;
  margin-bottom: 13px;
  margin-left: 16px;
  margin-right: 16px;
}

input {
  -webkit-appearance: none;
}

::placeholder {
  font-size: 16px;
  color: rgba(38, 44, 57, 0.3);
}
