@import '../../shared/colors.module.scss';

.container {
  position: sticky;
  right: 0;
  top: 0;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  z-index: 9999;
}

.toast {
  background: $outer-space;
  border-radius: 4px;
  padding: 16px 54px;
}

.text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: white;
  margin: 0;
}

.error {
  background: $cosmos;
  border: 1px solid rgba($cosmos, 0.25);
}

.errorText {
  color: $persian-red;
}

.success {
  background: $frosted-mint;
  border: 1px solid rgba($niagara, 0.25);
}

.successText {
  color: $niagara;
}

@media (max-width: 481px) {
  .toast {
    padding: 16px;
  }
}
