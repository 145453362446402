@import '../../shared/colors.module.scss';

.container {
  padding: 18px 16px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background-color: white;
}

.label {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 11px;
}

.optionsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectsContainer {
  display: flex;
  flex: 2;
}

.selectOptionContainer {
  width: 100%;
  margin-right: 16px;
}

.releaseDateContainer {
  margin: 0 16px;
  flex: 1;
}

.releaseDateLabel {
  font-size: 12px;
  margin: 0;
}

.releaseDateValue {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

@media (max-width: 768px) {
  .optionsContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .selectsContainer {
    width: 100%;
  }

  .selectOptionContainer:last-of-type {
    margin-right: 0;
  }

  .releaseDateContainer {
    margin: 0;
    margin-top: 16px;
  }
}

@media (max-width: 481px) {
  .selectsContainer {
    flex-direction: column;
  }

  .selectOptionContainer {
    margin: 0;
    margin-bottom: 16px;
  }

  .selectOptionContainer:last-of-type {
    margin-bottom: 8px;
  }
}
