@import '../../../shared/colors.module.scss';

.container {
  background: white;
  border: 1px solid $geyser;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 18px 16px;
}

.label {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.description {
  font-size: 14px;
  line-height: 21px;
  margin-top: 4px;
  margin-bottom: 16px;
}

.uploadButton {
  border: none;
  color: $ebony-clay;
  text-align: center;
  background: $catskill-whiteapprox;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  margin-top: 14px;
}

.uploadIcon {
  margin-right: 5px;
}

.fileStatusIconUploading path {
  stroke: $royal-blue;
}

.fileStatusIconFailed path {
  stroke: $persian-red;
}

.fileStatusTextUploading {
  color: $royal-blue;
}

.fileStatusTextFailed {
  color: $persian-red;
}

input[type='file'] {
  display: none;
}

.fileInfo {
  background: white;
  border: 1px solid $geyser;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 11px 16px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fileInfoLeft {
  display: flex;
  align-items: center;
}

.fileIcon {
  margin-right: 10px;
}

.fileName {
  line-height: 24px;
  font-weight: 500;
  word-break: break-all;
}

.removeFile {
  background: $catskill-whiteapprox;
  padding: 8px 20.5px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

@media (max-width: 768px) {
  .fileInfo {
    flex-direction: column;
    align-items: flex-start;
  }

  .removeFile {
    margin-top: 12px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }
}
