@import '../../shared/colors.module.scss';

.inputContainer {
  display: flex;
  align-items: center;
  width: 100%;
  background: white;
  border: 1px solid $geyser;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  height: 48px;
  padding: 14px 12px;
  outline: none;
  -webkit-appearance: none;
  resize: none;
}

.input {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  -webkit-appearance: none;
  resize: none;
}

input {
  -webkit-appearance: none;
}

::placeholder {
  font-size: 16px;
  color: rgba(38, 44, 57, 0.3);
}

.icon {
  font-size: 16px;
  font-weight: 600;
  margin-right: 8px;
}

.error {
  border: 1px solid $carnation;
}
