.emptyBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 82px 32px;
  background: white;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.title {
  font-weight: 600;
  margin-top: 26px;
  margin-bottom: -5px;
}

.description {
  font-size: 14px;
  line-height: 24px;
  white-space: pre-line;
  text-align: center;
}

.secondaryDescription {
  font-weight: 500;
  margin: 0;

  a {
    text-decoration: underline;
  }
}

@media (max-width: 481px) {
  .emptyBox {
    padding: 32px;
    text-align: center;
  }
}
