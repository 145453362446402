.container {
  max-width: 821px;
  margin: 0 auto;
  padding: 32px 56px;
  padding-bottom: 56px;
}

.formContainer {
  padding-top: 24px;
}

.saveButtonContainer {
  margin-top: 24px;
}

@media (max-width: 481px) {
  .container {
    padding: 24px 16px;
  }

  .formContainer {
    padding-top: 16px;
  }
}
