@import '../../shared/colors.module.scss';

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 32px 56px;
  padding-bottom: 56px;
}

.formInputContainer {
  margin-bottom: 25px;
}

.progressBar {
  width: 100%;
  height: 8px;
  background-color: rgba(38, 44, 57, 0.13);
}

.progressCompleted {
  width: 100%;
  height: 100%;
  background: $royal-blue;
}

.back {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 42px;

  span {
    color: $royal-blue;
    margin-left: 8px;
  }
}

.backButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.backDisabled {
  cursor: default;
  user-select: none;
  pointer-events: none;
  opacity: 0.3;
}

.stepHeader {
  margin-bottom: 30px;
}

.stepTitle {
  margin-bottom: 0;
}

.tipsContainer {
  margin-top: 32px;
}

.tipElementContainer {
  margin-bottom: 16px;
}

.editStepElementContainer {
  margin-bottom: 16px;
}

.backButtonContainer {
  margin-right: 4px;
  width: 100%;
}

.skipButtonContainer {
  margin-top: 16px;
  width: 100%;
}

.publishTitle {
  margin-bottom: 14px;
}

.publishDescription {
  font-size: 16px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 32px;
}

.publishButtonContainer {
  margin-bottom: 16px;
}

.editSkillsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  padding-bottom: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background-color: white;
}

.editEmptySkillsContainer {
  @extend .editSkillsContainer;
  padding-bottom: 24px;
}

.editSkillsLabel {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 8px;
}

.editEmptySkillsLabel {
  @extend .editSkillsLabel;
  margin-bottom: 16px;
}

.editSkillsList {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  margin-left: -4px;

  li {
    display: flex;
    align-items: flex-start;
    width: 46%;
    margin: 8px;
    line-height: 24px;

    .editSkillsElementIcon {
      margin-top: 6px;
      margin-right: 10px;
    }
  }
}

.editSkillsButton {
  background-color: $catskill-whiteapprox;
  border-radius: 4px;
  color: $ebony-clay;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  padding: 9px 19px;
  margin-left: 8px;
  cursor: pointer;
}

.editEmptySkillsButton {
  @extend .editSkillsButton;
}

@media (max-width: 768px) {
  .editSkillsContainer {
    flex-direction: column;
    padding-bottom: 24px;
  }

  .editEmptySkillsContainer {
    flex-direction: row;
  }

  .editSkillsList {
    margin-left: 0;

    li {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .editSkillsButton {
    margin-left: 0;
    margin-top: 12px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }

  .editEmptySkillsButton {
    width: auto;
  }
}

@media (max-width: 481px) {
  .container {
    padding: 24px 16px;
  }

  .back {
    margin-top: 8px;
    margin-bottom: 32px;
  }

  .editSkillsContainer {
    padding: 16px;
  }

  .editEmptySkillsContainer {
    flex-direction: column;
    padding: 18px 16px;
  }

  .editSkillsList {
    margin: 0;
    margin-top: 8px;

    li {
      width: 100%;
      margin: 8px 0;
    }
  }

  .editSkillsLabel {
    margin-bottom: 11px;
  }

  .editSkillsButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 8px;
    margin-left: 0;
  }

  .editSkillsButton {
    margin-top: 16px;
  }
}
