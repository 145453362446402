@import '../../shared/colors.module.scss';

.container {
  max-width: 1328px;
  margin: 0 auto;
  padding: 32px 56px;
  padding-bottom: 56px;
}

.title {
  margin: 0;
  margin-top: 21px;
}

.navigation {
  display: flex;
  border-bottom: 1px solid $botticelli;
  margin-top: 48px;
  margin-bottom: 24px;
  padding: 0;
  overflow: scroll;
  overflow-y: hidden;
}

.navigation::-webkit-scrollbar {
  width: 0px;
  height: 0;
  background: transparent;
}

.navigationItem {
  position: relative;
  display: block;
  color: $shuttle-gray;
  font-weight: 500;
  padding: 8px 10px;
  padding-top: 0;
  margin-right: 29px;
  flex-shrink: 0;
  cursor: pointer;
}

.navigationItemSelected {
  color: $ebony-clay;
  font-weight: 600;
}

.navigationItemSelectedIndicator {
  height: 2px;
  width: 100%;
  background: $ebony-clay;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.formsContainer {
  max-width: 800px;
}

.formContainer {
  padding-top: 24px;
}

.saveButtonContainer {
  margin-top: 24px;
}

.paymentContainer {
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background-color: white;
  margin-bottom: 24px;
  padding: 32px;
}

.paymentInfo {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.paymentsSectionTitle {
  margin-top: 44px;
  margin-bottom: 35px;
}

.paymentTitle {
  margin: 0;
}

.paymentDescription {
  color: $shuttle-gray;
  margin: 0;
  margin-top: 11px;
  margin-right: 16px;
  line-height: 24px;
  max-width: 632px;
}

.paymentButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paymentButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: $catskill-whiteapprox;
  width: 139px;
  height: 48px;
  font-size: 14px;
  font-weight: 600;
  color: $shuttle-gray;
  cursor: pointer;
}

.connectPaymentButton {
  @extend .paymentButton;
  background: $royal-blue;
  color: white;
}

.learnMorePaymentButton {
  font-weight: 500;
  color: $shuttle-gray;
  margin-top: 16px;
}

.paymentMethodInfoContainer {
  display: flex;
  align-items: center;
}

.paymentMethodConnected {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: $observatory;
  margin-left: 8px;
}

.paymentMethodFailed {
  @extend .paymentMethodConnected;
  color: $persian-red;
}

.paymentMethodConnectedIcon path {
  stroke: $observatory;
}

.saveButtonContainer {
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .paymentContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .paymentMethodInfoContainer {
    margin-top: 16px;
  }

  .paymentButtonsContainer {
    width: 100%;
  }

  .paymentButton {
    margin-top: 16px;
    width: 100%;
  }
}

@media (max-width: 481px) {
  .container {
    padding: 24px 16px;
  }

  .title {
    margin: 0;
  }

  .paymentsSectionTitle {
    margin: 0;
    margin-bottom: 24px;
  }

  .navigation {
    margin-top: 32px;
    margin-bottom: 24px;
  }

  .formContainer {
    padding-top: 16px;
  }

  .paymentContainer {
    padding: 16px;
    margin-bottom: 16px;
  }

  .paymentMethodInfoContainer {
    margin-top: 8px;
  }
}
