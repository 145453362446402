.container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 24px;
  width: 24px;
}

.label {
  margin-left: 8px;
  font-weight: 500;
}

.icon {
  height: 10px;
}
