@import '../../shared/colors.module.scss';

.episodeContainer {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  padding: 32px;
}

.episodeInfoContainer {
  flex: 1;
}

.episodeTitle {
  margin-top: 0;
  margin-bottom: 8px;
}

.episodeDetails {
  margin: 7px 0;
  margin-top: 0;
}

.episodeDetailsSeparator {
  color: $geyser;
  margin: 0 5px;
}

.episodeDetails:last-child {
  margin-bottom: 0;
}

.episodeStatus {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.episodeStatusIcon {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.episodeStatusIconUploading path {
  stroke: $royal-blue;
}

.episodeStatusIconFailed path {
  stroke: $persian-red;
}

.episodeStatusText {
  word-break: break-all;
  line-height: 24px;
  font-weight: 500;
}

.episodeStatusTextUploading {
  color: $royal-blue;
}

.episodeStatusTextFailed {
  color: $persian-red;
}

.editButtonContainer {
  width: 139px;
  margin-left: 16px;
}

@media (max-width: 768px) {
  .episodeContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .episodeStatus {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .editButtonContainer {
    margin: 0;
    margin-top: 16px;
    width: 100%;
  }
}

@media (max-width: 481px) {
  .episodeContainer {
    padding: 16px;
  }

  .episodeDetails {
    display: flex;
    flex-direction: column;
  }

  .episodeDetailsSeparator {
    height: 4px;
    background: transparent;
  }
}
