@import '../../../shared/colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  border: 1px solid $geyser;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 18px 16px;
}

.label {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.description {
  font-size: 14px;
  line-height: 21px;
  margin: 0;
}

.inputsContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;
  box-sizing: border-box;
}

.skillInputContainer {
  width: 50%;
}

.addSkill {
  display: flex;
  cursor: pointer;
  margin-top: 8px;
  margin-left: 14px;
  margin-bottom: 5px;
}

.addSkillLabel {
  font-size: 14px;
  font-weight: 600;
  margin-left: 9px;
}

.negativeMargin {
  margin-left: -8px;
}

.positiveMargin {
  margin-left: 8px;
}

.description {
  font-size: 14px;
  line-height: 21px;
  margin-top: 4px;
  margin-bottom: 16px;
}

.tipContainer {
  width: 100%;
  margin-top: 16px;
}

@media (max-width: 768px) {
  .skillInputContainer {
    width: 100%;
  }

  .inputsContainer {
    padding: 0;
  }

  .negativeMargin,
  .positiveMargin {
    margin: 0;
  }
}

@media (max-width: 481px) {
  .description {
    margin-top: 16px;
  }
}
