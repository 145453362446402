@import '../../shared/colors.module.scss';

header {
  padding: 14px 0;
  width: 100%;
  background: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid $geyser;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerContent {
  max-width: 1326px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 0px 32px;
  align-items: center;
  justify-content: space-between;
}

.profileImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  background: white;
  border: 1px solid $geyser;
  cursor: pointer;
}

.userContainer {
  position: relative;
}

.menu {
  z-index: 9999;
  position: absolute;
  right: 0;
  top: 46px;
  width: 176px;
  padding: 8px 0;
  margin: 0;
  border-radius: 8px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px $geyser;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  li {
    display: block;
    padding: 11px 16px;
    cursor: pointer;
    color: $ebony-clay;
  }

  li:hover {
    background: $athens-grayapprox;
  }
}

.loginButton {
  text-align: center;
  background: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border: 1px solid $geyser;
  padding: 5px 41px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  box-sizing: border-box;
}

@media (max-width: 481px) {
  .headerContent {
    box-sizing: border-box;
    padding: 0 16px;
  }
}
