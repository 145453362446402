@import '../../shared/colors.module.scss';

.formContainer {
  margin: 0 auto;
  display: flex;
  max-width: 400px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 16px;
  padding-bottom: 56px;
}

.title {
  margin-top: 62px;
  margin-bottom: 40px;
  text-align: center;
}

.signUpButtonContainer {
  margin-top: 8px;
  margin-bottom: 16px;
  width: 100%;
}

.login {
  margin: 0;
  margin-top: 32px;
  text-align: center;

  a,
  span {
    font-weight: 600;
    cursor: pointer;
  }
}

.termsAndConditions {
  margin-top: 8px;
  margin-bottom: 32px;
  font-size: 14px;
  color: $shuttle-gray;
  text-align: center;
}

.termsAndConditionsLink {
  font-weight: 600;
}

.termsAndConditionsLink:hover {
  text-decoration: underline;
}

.separator {
  width: 100%;
  height: 1px;
  background: $geyser;
}

.error {
  color: $carnation;
  margin-top: 0;
  margin-bottom: 8px;
}

@media (max-width: 481px) {
  .formContainer {
    padding-top: 16px;
  }

  .title {
    margin: revert;
  }
}
