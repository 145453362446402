@import '../../shared/colors.module.scss';

.popupContainer {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  overflow: scroll;
  box-sizing: border-box;
}

.container {
  position: relative;
  width: 100%;
  max-width: 504px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background-color: $athens-grayapprox;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-height: 100%;
  overflow: scroll;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 16px;
}

.linkContainer {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px $geyser;
  background-color: white;
  padding: 16px;
  padding-bottom: 24px;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.linkContainer:last-of-type {
  margin-bottom: 0;
}

.linkElement {
  margin-bottom: 16px;
}

.linkElement:last-of-type {
  margin-bottom: 0;
}

.label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 8px;
}

.fieldContainer {
  display: flex;
  align-items: center;
  width: 100%;
  background: white;
  border: 1px solid $geyser;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  height: 48px;
  padding-right: 8px;
  margin-top: 8px;
}

.inputContainer {
  position: relative;
  height: 100%;
  width: 100%;
}

.input {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 14px 12px;
  padding-right: 0;
  outline: none;
  -webkit-appearance: none;
  resize: none;
  color: $shuttle-gray;
}

.copyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 99px;
  height: 32px;
  border-radius: 4px;
  background: $catskill-whiteapprox;
  cursor: pointer;

  span {
    font-size: 14px;
    font-weight: 600;
  }
}

.copiedButton {
  background: $niagara;

  span {
    color: white;
  }
}

.gradient {
  position: absolute;
  top: 0;
  right: 0;
  background-image: linear-gradient(to left, white, rgba(255, 255, 255, 0));
  height: 100%;
  width: 110px;
  user-select: none;
  pointer-events: none;
}

.tipContainer {
  display: flex;
  padding: 12px;
  background: $frosted-mint;
  border-radius: 3px;
  margin-bottom: 16px;
}

.tipIcon {
  height: 24px;
  width: 24px;
  margin-right: 8px;
}

.tipText {
  margin: 0;
  line-height: 20.8px;
}

.tipTextWithEmptyText {
  content: '';
}

@media (max-width: 481px) {
  .closeButton {
    height: 14px;
    width: 14px;
  }

  .tipIcon {
    position: absolute;
  }

  .tipText {
    line-height: 24px;
  }

  .tipTextWithEmptyText::before {
    content: '\a0\a0\a0\a0\a0\a0\a0';
  }
}

@media (max-width: 360px) {
  .closeButton {
    height: 12px;
    width: 12px;
  }
}
